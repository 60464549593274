<template>
  <div>
    <b-row
      class="mb-2"
      v-if="!hideActionBar"
    >
      <b-col cols="12" sm="12" md="8" xl="6" lg="6">
        <go-back :title="$t('identification.table.title')">
          <b-button
              variant="primary"
              block
              :to="{ name: 'identification-create' }"
              v-if="$auth.check('identification-create')"
          >
            <feather-icon icon="SearchIcon" class="mr-50"/>
            {{ $t("actions.identify") }}
          </b-button>
        </go-back>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("identification.table.title") }}</b-card-title>
      </b-card-header>
      <data-table
        :fetchData="fetchData"
        :columns="columns"
        v-if="$auth.check('identification-list')"
      >
        <template slot="row" slot-scope="props">
          <user
            v-if="props.column.field === 'user.name'"
            :user="props.row.user"
          />

          <!-- Column: Query Photo -->
          <span v-else-if="props.column.field === 'url'">
            <img
              :src="thumbnail(props.row)"
              class="rounded b-avatar b-avatar-lg"
              v-lightbox
              :key="props.row.photo.id"
              :data-group="`identification-${props.row.id}`"
              :data-original="props.row.photo.media.url"
            />
          </span>

          <!-- Status -->
          <span v-else-if="props.column.field === 'status'" class="mx-auto">
            <b-badge
              :variant="statusColor(props.row.status)"
              class="text-white d-block"
            >
              {{ $t("identification.status")[props.row.status] }}
            </b-badge>
          </span>

          <!-- Created at -->
          <span v-else-if="props.column.field === 'created_at'">
            {{ $d(new Date(props.row.created_at * 1000), "full", "en") }}
          </span>

          <span v-else-if="props.column.field === 'matches'">
            <b-badge
              v-if="props.row.status == 'processing'"
              class="d-block"
              variant="light-warning"
            >
              <b-spinner small variant="warning" />
            </b-badge>
            <b-avatar-group size="49px" v-else>
              <div
                v-for="match in props.row.matches"
                :key="match.id"
                style="width: 49px; margin-right: -10px"
              >
                <img
                  :id="'avatar-' + match.id"
                  :src="thumbnail(match)"
                  tabindex="0"
                  style="padding-right: -15px"
                  class="pull-up rounded b-avatar b-avatar-lg"
                  :data-group="`identification-${props.row.id}`"
                  :data-ignore="true"
                  :data-original="original(match)"
                  v-lightbox
                  :key="props.row.photo.id"
                />
                <b-popover
                  :target="'avatar-' + match.id"
                  :triggers="['hover', 'focus']"
                  :delay="{ show: '150ms' }"
                  class="shadow-lg p-0"
                  boundary="window"
                  style="width: 300px"
                >
                  <query-match :match="match" />
                </b-popover>
              </div>
            </b-avatar-group>
          </span>

          <!-- Platform -->
          <template v-else-if="props.column.field === 'platform'" class="mx-auto">
            <cellphone-icon v-if="props.row.platform === 'mobile'" title="Mobile" :size="32"/>
            <desktop-icon v-else-if="props.row.platform === 'desktop'"  title="Desktop" :size="32"/>
          </template>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <action-button
                icon="EyeIcon"
                :title="$t('person.actions.show')"
                :to="{
                  name: 'identification-show',
                  params: { id: props.row.id },
                }"
                permissions="identification-show"
              />
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </data-table>
    </b-card>
  </div>
</template>

<script>
import QueryMatch from "./components/QueryMatch.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import { index } from "@/api/queries.api";
import ActionButton from "@/components/DataTable/ActionButton.vue";
import User from "@/components/User.vue";
import GoBack from "@/components/Common/GoBack";
import CellphoneIcon from 'vue-material-design-icons/Cellphone.vue';
import DesktopIcon from 'vue-material-design-icons/Monitor.vue';

export default {
  components: {GoBack, FeatherIcon, DataTable, QueryMatch, ActionButton, User, CellphoneIcon, DesktopIcon },
  props: {
    predefinedFilters: { type: Object, default: null },
    hideActionBar: { type: Boolean, default: false },
  },
  name: "QueryList",
  data() {
    return {
      columns: [
        {
          label: this.$t("fields.query-source"),
          field: "user.name",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.query-source"),
          },
        },
        {
          label: this.$t("fields.organization_name"),
          field: "organization.name",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        {
          label: this.$t("fields.provided_photo"),
          field: "url",
          sortable: false,
        },
        {
          label: this.$t("fields.status"),
          field: "status",
          width: "180px",
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.all"),
            filterDropdownItems: [
              {
                text: this.$t("identification.status.pending"),
                value: "pending",
              },
              {
                text: this.$t("identification.status.processing"),
                value: "processing",
              },
              { text: this.$t("identification.status.match"), value: "match" },
              {
                text: this.$t("identification.status.no-match"),
                value: "no-match",
              },
              {
                text: this.$t("identification.status.failed"),
                value: "failed",
              },
            ],
          },
        },
        {
          label: this.$t("fields.matches"),
          field: "matches",
          sortable: false,
        },
        {
          label: this.$t("fields.date"),
          field: "created_at",
          sortable: true,
        },
        {
          label: this.$t("fields.platform"),
          field: "platform",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.all"),
            filterDropdownItems: [
              {
                text: this.$t("fields.desktop"),
                value: 'desktop',
              },
              {
                text: this.$t("fields.mobile"),
                value: 'mobile',
              },
            ],
          },
        },
        {
          label: "Actions",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    statusColor(status) {
      return {
        pending: "light-info",
        processing: "light-info",
        match: "light-success",
        "no-match": "light-warning",
        failed: "light-danger",
      }[status];
    },
    async fetchData(filters) {
      return await index({ ...filters, ...this.predefinedFilters });
    },
    thumbnail(model) {
      return model?.photo?.media?.thumbnail;
    },
    original(model) {
      return model?.photo?.media?.url;
    },
  },
};
</script>

<style lang="scss">
.popover {
  max-width: 350px !important;
}

.material-design-icon {
  height: auto;
  width: auto;
}

.material-design-icon > .material-design-icon__svg {
  height: auto;
  position: unset;
  width: auto;
}

@media only screen and (max-width: 992px) {
  .query-match {
    & > div {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .person-info {
    order: 3;
    width: 100%;
    margin-top: 1rem;
  }
}
</style>