<template>
  <b-card no-body>
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2 d-flex align-items-baseline">
        <span class="ml-50">{{ $t("personnel.info.title") }}</span>
      </b-card-title>
      <span class="text-muted" style="font-size: 0.84rem; justify-self: right">
        {{ $d(new Date(info.created_at * 1000), "full", "en") }}
      </span>
    </b-card-header>
    <div class="mb-0 table-responsive">
      <table class="table b-table table-striped">
        <tbody class="w-100">
          <tr>
            <td>{{ $t("fields.country") }}</td>
            <td>
              <template v-if="country">
                {{ country.flag }} {{ country.name }}
              </template>
              <span v-else> -- </span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("fields.platform") }}</td>
            <td>{{ info.platform }}</td>
          </tr>

          <tr>
            <td>{{ $t("fields.browser") }}</td>
            <td>{{ info.browser }}</td>
          </tr>

          <tr>
            <td>{{ $t("fields.device") }}</td>
            <td>{{ info.device }}</td>
          </tr>

          <tr>
            <td>{{ $t("fields.ip") }}</td>
            <td>{{ info.ip }}</td>
          </tr>

          <tr>
            <td>{{ $t("fields.desktop") }}</td>
            <td>
              <b-avatar
                size="sm"
                :variant="info.is_desktop ? 'light-success' : 'light-danger'"
              >
                <feather-icon icon="CheckIcon" v-if="info.is_desktop" />
                <feather-icon icon="XIcon" v-else />
              </b-avatar>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["info"],
  computed: {
    ...mapGetters(["countryByIso"]),

    country() {
      if (!this.info?.country) return null;
      return this.countryByIso(this.info.country.toUpperCase());
    },
  },
  methods: {
    ...mapActions(["fetchCountries"]),
  },
  created() {
    this.fetchCountries();
  },
};
</script>

<style>
</style>