<template>
  <div>
    <b-row
      class="mb-2"
      v-if="!hideActionBar"
    >
      <b-col cols="12" sm="12" md="8" xl="6" lg="6">
        <go-back :title="$t('verification.table.title')">
          <b-button variant="primary" block :to="{ name: 'verification-create' }"  v-if="$auth.check('verification-create')">
            <feather-icon icon="SearchIcon" class="mr-50" />
            {{ $t("actions.verify") }}
          </b-button>
        </go-back>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("verification.table.title") }}</b-card-title>
      </b-card-header>
      <data-table
        :fetchData="fetchData"
        :columns="columns"
        v-if="$auth.check('verification-list')"
      >
        <template slot="row" slot-scope="props">
          <user
            v-if="props.column.field === 'user.name'"
            :user="props.row.user"
          />

          <!-- Status -->
          <span v-else-if="props.column.field === 'status'" class="mx-auto">
            <b-badge
              :variant="statusColor(props.row.status)"
              class="text-white d-block"
            >
              {{ $t("verification.status")[props.row.status] }}
            </b-badge>
          </span>

          <!-- Column: Similarity -->
          <span v-else-if="props.column.field === 'similarity'">
            <similarity-small
              :similarity="props.row.similarity"
              v-if="props.row.similarity"
            />
            <b-badge
              v-if="props.row.status == 'processing'"
              class="d-block"
              variant="light-warning"
            >
              <b-spinner small variant="warning" />
            </b-badge>
          </span>

          <!-- Column: Photo A -->
          <span v-else-if="props.column.field === 'photo_a'">
            <img
              :src="thumbnail(props.row.photo_a)"
              tabindex="0"
              style="padding-right: -15px"
              class="rounded avatar b-avatar-lg"
              v-lightbox
              :key="props.row.photo_a.id"
              :data-group="`verification-${props.row.id}`"
              :data-original="props.row.photo_a.media.url"
            />
          </span>

          <!-- Column: Photo B -->
          <span v-else-if="props.column.field === 'photo_b'">
            <img
              :src="thumbnail(props.row.photo_b)"
              size="lg"
              tabindex="0"
              style="padding-right: -15px"
              class="rounded avatar b-avatar-lg mx-auto"
              v-lightbox
              :key="props.row.photo_b.id"
              :data-group="`verification-${props.row.id}`"
              :data-original="props.row.photo_b.media.url"
            />
          </span>

          <!-- Column: Created at -->
          <span v-else-if="props.column.field === 'created_at'">
            {{ $d(new Date(props.row.created_at * 1000), "full", "en") }}
          </span>

          <!-- Platform -->
          <template v-else-if="props.column.field === 'platform'" class="mx-auto">
            <cellphone-icon v-if="props.row.platform === 'mobile'" title="Mobile" :size="32"/>
            <desktop-icon v-else-if="props.row.platform === 'desktop'"  title="Desktop" :size="32"/>
          </template>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <action-button
                icon="EyeIcon"
                :title="$t('person.actions.show')"
                :to="{
                  name: 'verification-show',
                  params: { id: props.row.id },
                }"
                permissions="verification-show"
              />
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </data-table>
    </b-card>
  </div>
</template>

<script>
import QueryMatch from "./components/QueryMatch.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import { index } from "@/api/verifications.api";
import ActionButton from "@/components/DataTable/ActionButton.vue";
import User from "@/components/User.vue";
import SimilaritySmall from "./components/SimilaritySmall.vue";
import GoBack from "@/components/Common/GoBack";
import CellphoneIcon from 'vue-material-design-icons/Cellphone.vue';
import DesktopIcon from 'vue-material-design-icons/Monitor.vue';

export default {
  components: {
    GoBack,
    FeatherIcon,
    DataTable,
    QueryMatch,
    ActionButton,
    User,
    SimilaritySmall,
    CellphoneIcon,
    DesktopIcon
  },
  name: "QueryList",
  props: {
    predefinedFilters: { type: Object, default: null },
    hideActionBar: { type: Boolean, default: false },
  },
  data() {
    return {
      columns: [
        {
          label: this.$t("fields.query-source"),
          field: "user.name",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.query-source"),
          },
        },
        {
          label: this.$t("fields.organization_name"),
          field: "organization.name",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        {
          label: this.$t("fields.status"),
          field: "status",
          width: "180px",
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.all"),
            filterDropdownItems: [
              {
                text: this.$t("verification.status.pending"),
                value: "pending",
              },
              {
                text: this.$t("verification.status.processing"),
                value: "processing",
              },
              {
                text: this.$t("verification.status.no-face"),
                value: "no-face",
              },
              {
                text: this.$t("verification.status.completed"),
                value: "completed",
              },
              { text: this.$t("verification.status.failed"), value: "failed" },
            ],
          },
        },
        {
          label: this.$t("fields.photo"),
          field: "photo_a",
          sortable: false,
        },
        {
          label: this.$t("fields.similarity"),
          field: "similarity",
        },
        {
          label: this.$t("fields.photo"),
          field: "photo_b",
          sortable: false,
        },
        {
          label: this.$t("fields.date"),
          field: "created_at",
        },
        {
          label: this.$t("fields.platform"),
          field: "platform",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("fields.all"),
            filterDropdownItems: [
              {
                text: this.$t("fields.desktop"),
                value: 'desktop',
              },
              {
                text: this.$t("fields.mobile"),
                value: 'mobile',
              },
            ],
          },
        },
        {
          label: "Actions",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    statusColor(status) {
      return {
        pending: "light-info",
        processing: "light-info",
        completed: "light-success",
        "no-face": "light-warning",
        failed: "light-danger",
      }[status];
    },
    async fetchData(filters) {
      return await index({ ...filters, ...this.predefinedFilters });
    },
    thumbnail(model) {
      return model?.media?.thumbnail;
    },
  },
};
</script>

<style>
.material-design-icon {
  height: auto;
  width: auto;
}

.material-design-icon > .material-design-icon__svg {
  height: auto;
  position: unset;
  width: auto;
}
</style>
