<template>
  <div
    :class="'query-match-container ' + (detail ? 'detail-visible' : '')"
    tabindex="0"
  >
    <div class="query-match" @click="detail = !detail" tabindex="0">
      <div style="display: flex; flex: 1">
        <img
          :src="match.photo.media.thumbnail"
          v-if="person"
          rounded
          class="avatar"
        />
        <feather-icon icon="UserIcon" v-else rounded class="avatar" />

        <div v-if="person" class="person-info">
          <div class="d-flex flex-column">
            <p>{{ person.name }}</p>
            <span v-if="person.date_of_birth" class="d-block">
              {{ $d(new Date(person.date_of_birth * 1000), "long", "en") }}
            </span>
            <span v-if="gender" class="d-block">{{ gender }}</span>
          </div>
          <tag-box
            :tags="match.photo.person.tags"
            style="margin-top: 0 !important"
          />
        </div>
        <div v-else class="person-info">Related person is deleted.</div>
        <div style="width: 110px">
          <match-similarity :similarity="match.similarity" />
        </div>
      </div>

      <b-button
        :to="{ name: 'person-show', params: { id: person.id } }"
        class="btn-icon rounded-circle link-button"
        variant="primary"
        size="sm"
        v-if="person"
      >
        <feather-icon icon="LinkIcon" />
      </b-button>

      <feather-icon
        v-if="properties.length > 0"
        class="chevron"
        icon="ChevronDownIcon"
        size="32"
      />
    </div>

    <b-collapse v-if="person && properties.length > 0" :visible="detail">
      <div class="query-match-detail">
        <b-list-group flush>
          <b-list-group-item v-for="prop in properties" :key="prop.key">
            <strong>{{ prop.key }}</strong>
            : {{ prop.value }}</b-list-group-item
          >
        </b-list-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import MatchSimilarity from "./MatchSimilarity.vue";
import TagBox from "@/components/TagBox.vue";

export default {
  name: "QueryMatch",
  props: ["match"],
  components: { MatchSimilarity, FeatherIcon, TagBox },
  data() {
    return {
      detail: false,
    };
  },
  computed: {
    properties() {
      return this.match.photo?.person?.properties ?? [];
    },
    personValid() {
      return this.match.photo;
    },
    person() {
      return this.match.photo?.person;
    },
    gender() {
      if (this.person?.gender) {
        return this.$t("person.gender")[this.person.gender];
      }
    },
  },
};
</script>


<style lang="scss">
.chevron {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: transform ease 0.5s;
}

.detail-visible .chevron {
  transform: rotateX(180deg);
  transition: transform ease 0.5s;
}

.query-match {
  display: flex;
  flex-direction: row;
  position: relative;

  .avatar {
    width: 128px;
    height: 128px;
    flex-basis: 128px;
    flex-shrink: 0;
    object-fit: cover;
    overflow: clip;
    border-radius: 0.5rem;
  }

  .person-info {
    margin-left: 1.5rem;
    display: block;
    width: 100%;
    flex-grow: 1;
  }
}

.query-match-detail {
  padding-top: 1.5rem;
}

.query-match-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.link-button {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.25rem;
  z-index: 20;
}
</style>

