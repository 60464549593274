<template>
  <b-row>
    <b-col xs="12" md="3">
      <h2>{{ person.name }}</h2>
      <span v-if="person.reference">
            <strong> Referans ID: </strong>{{ person.reference }}
          </span>
      <span v-if="person.date_of_birth" class="d-block">
            <strong>{{ $t("fields.date-of-birth") }}:</strong>
            {{ $d(new Date(person.date_of_birth * 1000), "long", "en") }}
          </span>
      <span v-if="gender" class="d-block">
            <strong>{{ $t("fields.gender") }}:</strong> {{ gender }}
          </span>
      <tag-box :tags="person.tags"/>
      <b-list-group flush v-for="prop in person.properties" :key="prop.key">
        <b-list-group-item>
          {{ prop.key }}: {{ prop.value }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
        v-if="person.photos.length"
        xs="12" md="6">
      <person-photo-manager
          class="person-foto-manager"
          :editable="false"
          :initial-photos="person.photos"
          :person-id="person.id"
      />
    </b-col>
  </b-row>
</template>

<script>
import {show} from "@/api/people.api";
import PersonPhotoManager from "@/components/Person/PersonPhotoManager.vue";
import TagBox from "@/components/TagBox.vue";

export default {
  components: {PersonPhotoManager, TagBox},
  name: "TimelinePerson",
  props: {
    target: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      // person: {
      //   name: null,
      //   date_of_birth: null,
      //   gender: null,
      //   photos: [],
      //   properties: [],
      //   tags: [],
      //   reference: null,
      // },
      loading: false,
    };
  },
  computed: {
    // personId() {
    //   return this.$route.params?.id;
    // },
    gender() {
      if (this.person?.gender) {
        return this.$t("person.gender")[this.person.gender];
      }
    },
    person() {
      return this.target;
    }
  },
  // watch: {
  //   personId() {
  //     if (this.personId) {
  //       this.fetchData();
  //     } else {
  //       // TODO: clear
  //     }
  //   },
  // },
  methods: {
    async fetch(id) {
      this.loading = true;
      const {data} = await show(id);

      Object.keys(this.person).map((key) => {
        this.person[key] = data[key];
      });

      this.loading = false;
    },
  },
  mounted() {
    // this.fetch(this.$route.params.id);
  },
};
</script>

<style>
.person-foto-manager .container {
  padding: 0;
  flex-wrap: nowrap;
  justify-content: unset;
}
.person-foto-manager .upload-button {
  max-width: 128px;
}
.person-foto-manager .upload-button img {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}
</style>