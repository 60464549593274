<template>
  <div class="d-flex flex-row align-items-center">
    <span
      class="font-weight-bold text-body-heading mr-1 flex-grow-1 text-right"
    >
      <span class="d-block">{{ similarity }}%</span>
      <span class="text-muted" style="font-size: 0.8rem">{{ label }}</span>
    </span>
    <vue-apex-charts
      type="radialBar"
      height="35"
      width="35"
      :options="options"
      :series="[similarity]"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "MatchSimilaritySmall",
  components: { VueApexCharts },
  props: {
    similarity: { type: String | Number, required: true },
  },
  computed: {
    label() {
      if (this.similarity < 70) return this.$t("query.similarity.none");
      if (this.similarity < 75) return this.$t("query.similarity.very_low");
      if (this.similarity < 80) return this.$t("query.similarity.low");
      if (this.similarity < 88) return this.$t("query.similarity.not_likely");
      if (this.similarity < 90) return this.$t("query.similarity.likely");
      return this.$t("query.similarity.highly_likely");
    },
  },
  methods: {
    color() {
      if (this.similarity < 70) return "#ef4444";
      if (this.similarity < 75) return "#fb923c";
      if (this.similarity < 80) return "#fde047";
      if (this.similarity < 88) return "#bef264";
      if (this.similarity < 90) return "#86efac";
      return "#4ade80";
    },
  },
  data() {
    return {
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -10,
            bottom: -10,
          },
        },
        colors: [this.color()],
        plotOptions: {
          radialBar: {
            startAngle: -130,
            endAngle: 130,
            hollow: {
              size: "22%",
            },
            track: {
              background: "#e9ecef",
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [this.color()],
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>