<template>
  <li class="timeline-item timeline-variant-primary">
    <div class="timeline-item-point" :style="`color: ${color}`"/>
    <div
        class="
        d-flex
        flex-sm-row flex-column flex-wrap
        justify-content-between
        mb-1 mb-sm-0
      "
    >
      <h6>{{ title }}</h6>
      <small class="text-muted">{{
          $d(new Date(item.timestamp * 1000), "full", "en")
        }}</small>
    </div>
    <component
        :is="component"
        v-if="this.item.target_type"
        :target="item.target"
    />
  </li>
</template>

<script>
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Person from "./Person.vue";
import Verification from "./Verification.vue";
import Identification from "./Identification.vue";
import Empty from "./Empty.vue";

export default {
  components: {AppTimelineItem, Person, Verification, Identification, Empty},
  props: ["item"],
  data() {
    return {
      colors: {
        auth: "#7367f0",
        identification: "#28c76f",
        verification: "#ff9f43",
        person: "#00cfe8",
        photo: "#00a1b5",
        billing: "#ea5455",
        organization: "#43aa8b",
        user: "#fcbf49",
      },
      components: {
        "App\\Models\\Person": Person,
        "App\\Models\\Verification": Verification,
        "App\\Models\\Identification": Identification,
      },
    };
  },
  computed: {
    color() {
      return this.colors[this.item.module] ?? "red";
    },
    component() {
      const c = this.components[this.item.target_type];

      return c ? c : Empty;
    },
    title() {
      const parts = [this.item.module, this.item.action, this.item.modifier]
      const title = parts.filter((part) => part !== null).join('_')
      return this.$t(`action.aliases.${title}`)
    }
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
// Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

$timeline-border-color: $border-color;

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  max-width: 100%;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 2;
    color: transparent;
    background-color: currentColor;
    opacity: 0.8;

    &:before {
      content: "";
      background-color: currentColor;
      opacity: 0.4;
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
                $theme-dark-border-color,
                $theme-dark-card-bg
        );
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>