<template>
  <div
    :class="'query-match-container ' + (detail ? 'detail-visible' : '')"
    tabindex="0"
  >
    <div class="query-match" @click="detail = !detail" tabindex="0">
      <img :src="match.photo.media.thumbnail" class="" />
      <div class="person-info">
        <match-similarity :similarity="match.similarity" />
        <p>
          <strong>{{ $t("fields.name") }}: </strong>
          {{ match.photo.person.name }}
        </p>
      </div>

      <feather-icon
        v-if="properties.length > 0"
        class="chevron"
        icon="ChevronDownIcon"
        size="32"
      />
    </div>
    <b-collapse v-if="properties.length > 0" :visible="detail">
      <div class="query-match-detail">
        <b-list-group flush>
          <b-list-group-item v-for="prop in properties" :key="prop.key">
            <strong>{{ prop.key }}</strong>
            : {{ prop.value }}</b-list-group-item
          >
        </b-list-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import MatchSimilarity from "./MatchSimilarity.vue";

export default {
  name: "QueryMatch",
  props: ["match"],
  components: { MatchSimilarity, FeatherIcon },
  data() {
    return {
      detail: false,
    };
  },
  computed: {
    properties() {
      return this.match.photo.person.properties ?? [];
    },
  },
};
</script>


<style lang="scss">
.chevron {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: transform ease 0.5s;
}

.detail-visible .chevron {
  transform: rotateX(180deg);
  transition: transform ease 0.5s;
}

.query-match {
  display: flex;
  flex-direction: row;
  position: relative;

  img {
    width: 128px;
    height: 128px;
    flex-shrink: 0;
    object-fit: cover;
    overflow: clip;
    border-radius: 0.5rem;
  }

  .person-info {
    margin-left: 1.5rem;
    display: block;
    width: 100%;
  }
}

.query-match-detail {
  padding-top: 1.5rem;
}

.query-match-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
