<template>
  <div class="similarity-container d-flex flex-nowrap">
    <div class="d-flex flex-column left-image">
      <h2>{{ $t("verification.show.photo_a") }}</h2>
      <img
          :src="photoA"
          data-group="verification"
          style="aspect-ratio: 1/1; object-fit: cover; width: 100%"
      />
    </div>
      <div class="similarity">
        <div v-if="processing">
          <p class="text-center status-label">
            {{ $t("common.status.processing") }}...
          </p>
          <div
              class="inprogress-indicator"
              :title="$t('common.status.processing')"
          >
            <feather-icon
                icon="CpuIcon"
                size="92"
                class="p-2 text-success"
            />
            <feather-icon
                icon="UserIcon"
                size="92"
                class="p-2 text-warning"
            />
          </div>
        </div>
        <template v-else>
          <template v-if="verification.status === 'completed'">
            <p class="text-center status-label">Similarity</p>
            <div class="mx-auto">
              <match-similarity :similarity="verification.similarity"/>
            </div>
          </template>
          <span v-else>
                <p class="text-center">
                  {{ $t("verification.status." + verification.status) }}
                </p>
              </span>
        </template>
      </div>

    <div class="d-flex flex-column right-image">
      <h2>{{ $t("verification.show.photo_b") }}</h2>
      <img
          :src="photoB"
          data-group="verification"
          style="aspect-ratio: 1/1; object-fit: cover; width: 100%"
      />
    </div>
  </div>
</template>

<script>
import {show} from "@/api/verifications.api";
import MatchSimilarity from "@/views/admin/queries/components/MatchSimilarity.vue";

export default {
  name: "TimelineVerification",
  components: {MatchSimilarity},
  props: {
    target: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      timer: null,
    };
  },
  computed: {
    photoA() {
      return this.verification?.photos[0]?.media.url;
    },
    photoB() {
      return this.verification?.photos[1]?.media.url;
    },
    processing() {
      return ["pending", "processing"].includes(this.verification.status);
    },
    verification() {
      return this.target;
    },
  },
  methods: {
    async fetch() {
      if (this.loading) return;
      //TODO: catch
      this.loading = this.processing;
      const {data} = await show(this.verification.id);
      if (!this.verification) {
        this.verification = data;
      } else {
        this.verification.status = data.status;
        this.verification.similarity = data.similarity;
      }
      this.loading = false;

      if (this.processing) {
        this.timer = setTimeout(() => {
          this.fetch();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.processing) {
      this.fetch();
    }
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";

img {
  max-width: 100%;
}

.inprogress-indicator {
  height: 92px !important;

  & > svg {
    position: absolute;
    width: 100%;
    left: 0;
    animation-direction: alternate;

    &:first-of-type {
      animation: fadeIn 2s ease-out infinite;
      animation-direction: alternate;
    }

    &:last-of-type {
      animation: fadeIn 2s ease-out infinite;
      animation-direction: alternate-reverse;
    }
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 1;
  }

  40% {
    display: block;
    opacity: 1;
  }

  60% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    display: none;
    opacity: 0;
  }

  40% {
    display: block;
    opacity: 0;
  }

  60% {
    display: block;
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.similarity {
  display: flex !important;
  align-self: center;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.left-image > img,
.right-image > img {
  border-radius: 0.5rem;
}

.status-label {
  font-size: 1.4rem;
  font-weight: semibold;
}

.similarity-container {
  gap: 1rem;
}

@media (max-width: (map-get($grid-breakpoints, md))) {
  .left-image > h2, .right-image > h2 {
    font-size: 1rem;
  }
}
</style>