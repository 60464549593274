import axios from "@/libs/axios";

export async function index(query = null) {
    try {
        const { data } = await axios.get('/api/v1/user-actions', { params: query });
        return { data };
    } catch (error) {
        return { error: null }
    }
}
