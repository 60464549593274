<template>
  <span class="">
    <avatar :original="original" :thumbnail="avatar" :text="user.name" :id="user.id" />
    {{ user.name }}
  </span>
</template>

<script>
import Avatar from "./Avatar.vue";
export default {
  name: "user",
  props: ["user"],
  components: { Avatar },
  computed: {
    avatar() {
      return this.user?.avatar?.thumbnail;
    },
    original() {
      return this.user?.avatar?.url;
    },
  },
};
</script>

<style>
</style>