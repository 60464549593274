<template>
  <b-card class="timeline">
    <b-card-header>
      <b-card-title> User Timeline </b-card-title>
      <b-check v-model="follow">Follow Timeline</b-check>
    </b-card-header>
    <b-row>
      <b-col cols="12">
        <app-timeline>
          <template v-for="action in data">
            <timeline-item :key="action.id" :item="action" />
          </template>
        </app-timeline>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <b-button
          @click="fetchNext"
          variant="primary"
          :disabled="totalCount <= data.length"
        >
          Load More...
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import TimelineItem from "./TimelineItem.vue";
import { index } from "@/api/user-actions.api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["userId"],
  components: {
    AppTimeline,
    AppTimelineItem,
    TimelineItem,
    ToastificationContent,
  },
  data() {
    return {
      data: [],
      latest: null,
      earliest: null,
      totalCount: 0,
      updatedCount: null,
      timer: null,
      follow: false,
    };
  },
  watch: {
    updatedCount() {
      if (!this.follow && this.updatedCount != this.totalCount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("timeline.new_events"),
            icon: "RotateCwIcon",
            variant: "info",
            actions: [
              {
                title: "Refresh",
                variant: "outline-info",
                onClick: () => this.fetchNext(),
              },
            ],
          },
        });
      }
    },
    follow() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.follow) {
        this.timer = setInterval(() => {
          this.fetchNext();
        }, 5000);
      }
    },
  },
  methods: {
    async fetchData() {
      const {
        data: { data, meta },
      } = await index({ userId: this.userId });
      this.data = data;
      this.totalCount = meta.total;
    },
    async fetchPrev() {
      if (this.data.length == 0) return this.fetchData();

      const {
        data: { data, meta },
      } = await index({
        userId: this.userId,
        earlierThan: this.data[this.data.length - 1].id,
      });

      if (meta.total > this.totalCount) {
        this.updatedCount = meta.total;
      }

      this.data = [...this.data, ...data];
    },

    async fetchNext() {
      if (this.data.length === 0) return this.fetchData();

      const {
        data: { data, meta },
      } = await index({
        userId: this.userId,
        newerThan: this.data[this.data.length - 1].id,
        /*  earlierThan: this.data[this.data.length - 1].id */
      });
      this.totalCount = meta.total;
      this.updatedCount = meta.total;
      this.data.push(...data);
    },
    clear() {
      this.data = [];
    },
  },
  mounted() {
    this.fetchData();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.timeline {
  max-width: 100%;
  overflow-x: auto;
}

.timeline img {
  max-width: 128px;
}
</style>