<template>
  <div class="d-flex align-items-center" style="gap: 1rem;">
    <div class="d-flex flex-column">
      <img :src="target.photo.media.thumbnail" class="rounded" style="aspect-ratio: 1/1; object-fit: cover; width: 100%;"/>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <span>{{ target.status }}</span>
      <b-avatar variant="light-primary">
        <feather-icon icon="ArrowRightIcon"/>
      </b-avatar>
    </div>

    <div>
      <span
          v-for="match in target.matches"
          :key="match.id"
          class="match-photo rounded"
      >
        <img
            :src="match.photo.media.thumbnail"
            style="aspect-ratio: 1/1; width: 100%; object-fit: cover"
        />
        <small class="match-similarity">
          {{ match.distance }} - %{{ match.similarity }}
        </small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["target"],
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
.match-photo {
  position: relative;
  overflow: clip;
  display: block;
}

.match-similarity {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000aa;
}

@media (max-width: (map-get($grid-breakpoints, md))) {
  .match-similarity {
    white-space: nowrap !important;
    font-size: 0.7rem;
  }
}
</style>