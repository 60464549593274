<template>
  <div v-if="!loading">
    <b-tabs pills>
      <template slot="tabs-start">
        <go-back :to="{ name: 'personnel-list' }" class="mr-1" />
      </template>
      <b-tab :title="$t('fields.details')">
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-row>
                <!-- User Info: Left col -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex justify-content-between flex-column"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <avatar
                      :text="user.name"
                      :original="user.avatar ? user.avatar.src : null"
                      :thumbnail="user.avatar ? user.avatar.url : null"
                      width="104"
                    />
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <h4 class="mb-0">
                          {{ user.name }}
                        </h4>
                        <span class="card-text">{{ user.email }}</span>
                        <div>
                          <b-badge
                            :variant="
                              user.status === 'active' ? 'success' : 'danger'
                            "
                            class="mt-1"
                            >{{ $t("common.status." + user.status) }}</b-badge
                          >
                        </div>
                      </div>
                      <div class="d-flex flex-wrap">
                        <b-button
                          :to="{
                            name: 'personnel-edit',
                            params: { id: userId },
                          }"
                          variant="primary"
                          v-if="
                            $auth.check('user-update') ||
                            userId == $auth.user().id
                          "
                        >
                          {{ $t("personnel.actions.update") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>

                <!-- Right Col: Table -->
                <b-col cols="12" md="6">
                  <table class="mt-2 mt-xl-0 w-100"></table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col sm="12" md="8">
            <permission-manager
              :permissions="user.permissions"
              :roles="user.roles"
              :guard="user.guard_name"
              :editable="false"
            />
          </b-col>
          <b-col sm="12" md="4" v-if="user.info">
            <user-info :info="user.info" />
          </b-col>
        </b-row>
      </b-tab>

      <template slot="tabs-end">
        <h1>
          <span style="padding-left: 1rem">
            {{ user.name }}
          </span>
        </h1>
      </template>

      <b-tab lazy title="Identification">
        <b-row>
          <b-col>
            <h2>
              {{ $t("menu.identifications") }}
            </h2>
            <keep-alive>
              <query-list
                :predefinedFilters="{ user_id: userId }"
                :hide-action-bar="true"
              />
            </keep-alive>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab lazy title="Verification">
        <b-row>
          <b-col>
            <h2>
              {{ $t("menu.verifications") }}
            </h2>
            <verification-list
              :predefinedFilters="{ user_id: userId }"
              :hide-action-bar="true"
            />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab
        lazy
        title="Timeline"
        v-if="$auth.user().guard_name === 'management'"
      >
        <b-row>
          <b-col>
            <h2>
              {{ $t("menu.timeline") }}
            </h2>
            <timeline :userId="userId" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
  <spinner v-else />
</template>

<script>
import { show } from "@/api/users.api.js";
import GoBack from "@/components/Common/GoBack.vue";
import PermissionManager from "@/components/PermissionManager.vue";
import Spinner from "@/components/Spinner.vue";
import QueryList from "@/views/admin/queries/List.vue";
import VerificationList from "@/views/admin/verifications/List.vue";
import UserInfo from "./components/UserInfo.vue";
import Timeline from "@/components/Timeline/Timeline.vue";
import Avatar from "@/components/Avatar.vue";

export default {
  components: {
    GoBack,
    PermissionManager,
    Spinner,
    QueryList,
    VerificationList,
    UserInfo,
    Timeline,
    Avatar,
  },
  name: "personnel-show",
  data() {
    return {
      loading: false,
      user: {
        name: "",
        email: "",
        status: "active",
        permissions: [],
        roles: [],
        avatar: null,
        guard_name: "organization",
        info: null,
      },
    };
  },
  computed: {
    userId() {
      return this.$route.params?.id;
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;

      const { data } = await show(this.userId);

      Object.keys(this.user).forEach((key) => {
        this.user[key] = data[key];
      });

      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .tablist {
    display: flex;
    flex-direction: row;
    li {
      display: block;
      width: 100%;
    }
  }
}
</style>