import axios from "@/libs/axios";

export async function index(query) {
  try {
    const { data } = await axios.get('/api/v1/users', { params: query });
    return { data };
  } catch (error) {
    return { error: null }
  }
}


export async function show(id) {
  try {
    const { data: { data }
    } = await axios.get(`/api/v1/users/${id}`)
    return { data }
  } catch (error) {
    return { error: error.response.data }
  }
}


export async function store(formData) {
  try {
    const { data: { data } } = await axios.post("/api/v1/users", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return { data }
  } catch (error) {
    return { error: error.response.data }
  }
}

export async function update(id, formData) {
  formData.append('_method', 'PUT')
  try {
    const { data: { data } } = await axios.post(`/api/v1/users/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return { data }
  } catch (error) {
    return { error: error.response.data }
  }
}
