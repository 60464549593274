<template>
  <div class="w-full">
    <vue-apex-charts
      v-if="similarity"
      type="radialBar"
      height="96"
      width="110"
      :options="chartOptions"
      :series="[similarity]"
    />
    <p class="text-center font-sm">{{ label }}</p>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "MatchSimilarity",
  components: {VueApexCharts},
  props: ["similarity"],
  data() {
    return {
      chartOptions: {
        chart: {
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.color()],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: "#ebe9f1",
              strokeWidth: "60%",
              margin: -1,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 8,
                color: "#5e5873",
                fontSize: "1.35rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [this.color()],
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 5,
            top: 5,
          },
        },
      },
    };
  },
  computed: {
    label() {
      if (this.similarity < 70) return this.$t("query.similarity.none");
      if (this.similarity < 75) return this.$t("query.similarity.very_low");
      if (this.similarity < 80) return this.$t("query.similarity.low");
      if (this.similarity < 88) return this.$t("query.similarity.not_likely");
      if (this.similarity < 90) return this.$t("query.similarity.likely");
      return this.$t("query.similarity.highly_likely");
    },
  },
  methods: {
    color() {
      if (this.similarity < 70) return "#ef4444";
      if (this.similarity < 75) return "#fb923c";
      if (this.similarity < 80) return "#fde047";
      if (this.similarity < 88) return "#bef264";
      if (this.similarity < 90) return "#86efac";
      return "#4ade80";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>