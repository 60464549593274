<template>
  <b-card no-body>
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon icon="LockIcon" size="18" />
        <span class="align-middle ml-50">Permission</span>
      </b-card-title>
      <template v-if="editable">
        <b-button
          class="ml-auto mr-1"
          variant="outline-warning"
          size="sm"
          @click="toggleLock"
          v-b-tooltip.hover.v-warning
          :title="$t('acl.manager.cta')"
        >
          <template v-if="locked">
            <feather-icon icon="LockIcon" />
            {{ $t("common.actions.locked") }}
          </template>
          <template v-else>
            <feather-icon icon="UnlockIcon" />
            {{ $t("common.actions.unlocked") }}
          </template>
        </b-button>
        <v-select
          :placeholder="$t('acl.manager.select-predefined')"
          style="width: 240px"
          v-model="selectedRole"
          :options="systemRoles"
          :reduce="(val) => val.value"
          :clearable="false"
          class="select-size-sm"
        >
          <template #option="{ label }">
            <span>{{ $t("acl.roles." + label) }}</span>
          </template>
        </v-select>
      </template>
      <template v-else-if="self">
        {{ $t("acl.manager.self_error") }}
      </template>
    </b-card-header>
    <b-table striped responsive class="mb-0" :items="permissionsData">
      <template #head()="head">
        {{ $t("acl.actions." + head.column) }}
      </template>
      <template #cell(module)="data">
        {{ $t("acl.modules." + data.value) }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          v-if="data.value"
          :checked="permissions.includes(data.value.name)"
          :disabled="!editable || locked"
          @change="(checked) => permissionToggle(data.value.name, checked)"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { index } from "@/api/acl.api";
import VSelect from "vue-select";
export default {
  components: { VSelect },
  props: {
    roles: { default: () => [] },
    permissions: { default: () => [] },
    guard: { type: String },
    editable: { type: Boolean, default: true },
    self: { type: Boolean, default: false },
  },
  data() {
    return {
      rawData: [],
      permissionsData: [],
      systemRoles: [],
      selectedRole: null,
      locked: false,
    };
  },
  watch: {
    roles() {
      this.selectedRole = this.roles[0] ?? null;
      if (this.selectedRole) {
        this.locked = true;
      }
    },
    selectedRole() {
      if (this.selectedRole) {
        this.locked = true;
        this.$emit("set", {
          permissions: this.rawData
            .find((e) => e.name == this.selectedRole)
            .permissions.map((p) => p.name),
          roles: [this.selectedRole],
        });
      }
    },
  },
  methods: {
    parseData() {
      this.systemRoles = this.rawData
        .filter((e) => e.guard_name == this.guard)
        .map((r) => ({ label: r.name, value: r.name }));

      const perms = {};

      this.rawData
        .filter((e) => e.guard_name == this.guard)

        .forEach(({ permissions }) => {
          let temp = {};

          permissions.forEach((permission) => {
            if (!perms[permission.module]) {
              perms[permission.module] = {
                module: permission.module,
                list: null,
                show: null,
                create: null,
                update: null,
                destroy: null,
              };
            }

            perms[permission.module][permission.action] = {
              name: permission.name,
            };
          });
        });
      this.permissionsData = Object.values(perms);
    },
    permissionToggle(name, checked) {
      this.$emit("change", { name, checked });
    },
    toggleLock() {
      if (this.locked) {
        this.locked = false;
        this.$emit("set", { roles: [] });
      }
    },
  },
  async mounted() {
    const { data } = await index();
    this.rawData = data;
    this.parseData();
  },
};
</script>

<style>
</style>