import axios from "@/libs/axios";

export async function index(query = null) {
  try {
    const { data: { data } } = await axios.get('/api/v1/acl', { params: query });
    return { data };
  } catch (error) {
    return { error: null }
  }
}
