import axios from "@/libs/axios";


export async function index(query) {
    try {
        const { data } = await axios.get("/api/v1/verifications", { params: query })
        return { data }
    } catch (error) {

    }
}


export async function store(payload) {
    try {
        const { data: { data } } = await axios.post("/api/v1/verifications", payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        return { data }
    } catch (error) {
        return { error: error.response.data }
    }
}

export async function show(id) {
    try {
        const { data: { data }
        } = await axios.get(`/api/v1/verifications/${id}`)
        return { data }
    } catch (error) {

    }
}

