<template>
  <div>
    <vue-apex-charts
      type="radialBar"
      :height="size"
      :options="chartOptions"
      :series="[similarity]"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  name: "MatchSimilarity",
  components: { VueApexCharts },
  props: {
    similarity: { type: String | Number, required: true },
    size: { type: Number, default: 92 },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ["#51e5a8"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: "#ebe9f1",
              strokeWidth: "60%",
              margin: -1,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 8,
                color: "#5e5873",
                fontSize: "1.35rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 5,
            top: 5,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>