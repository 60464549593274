<template>
  <span class="mr-1" :title="text">
    <span
      class="b-avatar badge-secondary rounded b-avatar-lg"
      :style="width ? `width: ${width}px; height: ${width}px;` : ''"
    >
      <span v-if="!small" class="b-avatar-text">{{ initial }}</span>
      <span v-else class="b-avatar-img">
        <img
          :src="thumbnail"
          class="rounded b-avatar b-avatar-lg"
          v-lightbox
          :key="id"
          :data-original="original"
        />
      </span>
    </span>
  </span>
</template>

<script>
export default {
  props: ["original", "thumbnail", "text", "width", "id"],
  computed: {
    small() {
      return this.thumbnail ?? this.original;
    },
    full() {
      return this.original ?? this.thumbnail;
    },
    initial() {
      return this.text
        ? this.text
            .split(" ")
            .map((w) => (w && w.length > 0 ? w[0] : null))
            .filter((c) => !!c)
            .join("")
        : "";
    },
  },
};
</script>

<style>
</style>