<template>
  <div v-if="tags && tags.length > 0" class="my-1 tag-box">
    <small v-for="tag in tags" :key="tag.id">
      <b-badge variant="primary" class="mr-1">
        {{ tag.name }}
      </b-badge>
    </small>
  </div>
</template>

<script>
export default {
  name: "tag-box",
  props: ["tags"],
};
</script>

<style>
</style>